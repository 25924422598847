.Page {
  background-color: #f8f8f8;
  min-height: 100vh;
  font-size: calc(3px + 2vmin);
}

.Page-logo {
  height: 25vmin;
  width: 25vmin;
  align-self: center;
  pointer-events: none;
}

.Page-header {
  background-color: #fff;
  color: #051c2c;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d0d0d0;
}

.Page-header-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Page-header-title > * {
  margin: 20px 0 20px 20px;
}

.Page-header-title .btn {
  margin: 0;
}

.Page-header-title a {
  color: #2251FF;
  text-decoration: none;
}

.Page-header-separator {
  width: 2px;
  background-color: #051c2c;
}

.Page-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
}

.Page-options > * {
  margin-bottom: 20px;
}

.Page-options .Page-btn {
  width: 100%;
}

.Page-content {
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 92vh;
}

.Page-content-panel {
  background-color: #f8f8f8;
  width: 20%;
  color: #bbb;
  /*font-size: ;*/
  padding: 20px;
}

.Page-content-panel h5 {
  color: #051c2c
}

.Page-content-center {
  background-color: #f8f8f8;
  width: 60%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Page-content-center textarea {
  margin: 20px;
  height: 40vh;
  resize: none;
}

.Page-content-center label {
  margin: 20px 20px -10px;
  font-size: medium;
}
.LoadingPage {
    background: #051c2c;
    background: linear-gradient(135deg, #051c2c 60%,#03339c 100%);
    background: -webkit-linear-gradient(-45deg, #051c2c 60%, #03339c 100%);
    min-height: 100vh;
    font-size: calc(3px + 2vmin);
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.LoadingPage .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #f8f8f8;
    padding: 25px;
    margin: 50px;
    min-width: 30%;
}

.LoadingPage .content h2, h4 {
    margin-top: 0;
}

.LoadingPage .content button {
    width: 100%;
}

.LoadingPage .content .error-msg {
    color: #E5546C
}
@import 'functions';
@import 'variable';

@font-face {
    font-family: 'Bower';
    src: url('../assets/fonts/Bower-Bold.woff');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'McKinsey Sans';
    src: url('../assets/fonts/McKinseySans-Light.woff');
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'McKinsey Sans';
    src: url('../assets/fonts/McKinseySans-LightItalic.woff');
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
}

@font-face {
    font-family: 'McKinsey Sans';
    src: url('../assets/fonts/McKinseySans-Regular.woff');
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'McKinsey Sans';
    src: url('../assets/fonts/McKinseySans-Italic.woff');
    font-weight: 400;
    font-stretch: normal;
    font-style: italic;
}

@font-face {
    font-family: 'McKinsey Sans';
    src: url('../assets/fonts/McKinseySans-Medium.woff');
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'McKinsey Sans';
    src: url('../assets/fonts/McKinseySans-MediumItalic.woff');
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
}

html {
    height: 100%;
    body {
        box-sizing: border-box;
        height: 100%;
        font-size: 16px;
        font-family: 'McKinsey Sans';
        margin: 0;
        color: #000000;
        font-weight: 400;
        line-height: 1.43;

        #root {
            height: rem(100);
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        p {
            margin: 0;
        }

        * {
            box-sizing: border-box;
        }

    }
}
